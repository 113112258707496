import DOMPurify from "dompurify";
import httpClient from "./http-client";

export default {
    checkAndStopCameraLive() {
        try {
            let items = localStorage.getItem("cameraLive");
            if (items) {
                items = JSON.parse(items);
                for (const key in items) {
                    let item = items[key];
                    httpClient.delete(`/api/cameras/${item.id}/live-view/`, false);
                    delete items[key];
                    localStorage.setItem("cameraLive", JSON.stringify(items));
                }
            }
        } catch (e) {
            console.error(e);
        }
    },
    getURLParamsFromAddressBar() {
        let result = {};
        try {
            let queryString =
                window.parent && window.parent.location.search
                    ? window.parent.location.search
                    : window.location.search;
            let urlParams = new URLSearchParams(queryString);
            urlParams.forEach((value, key) => {
                if (value != null && value != "" && value != undefined) result[key] = value;
            });
        } catch (e) {
            console.error(e);
        }
        return result;
    },
    clearURLParamsFromAddressBar() {
        let filteredURL = "?";
        window.parent
            ? window.parent.history.pushState({}, "", filteredURL)
            : window.history.pushState({}, "", filteredURL);
    },
    decodeHtmlEntities(encodedHtml) {
        if (!encodedHtml) return "";
        const parser = new DOMParser();
        const decodedString = parser.parseFromString(encodedHtml, "text/html").body.textContent;
        const sanitized = DOMPurify.sanitize(decodedString, { ADD_TAGS: ["iframe"] });
        return sanitized;
    },
    encodeHtml(html) {
        const tempElement = document.createElement("div");
        const textNode = document.createTextNode(html);
        tempElement.appendChild(textNode);
        return tempElement.innerHTML;
    },
    clearLocalStorage() {
        const keysToPreserve = ["cookie-consent", "x-log-role", "fab-state"];
        const preservedValues = {};

        keysToPreserve.forEach((key) => {
            const value = localStorage.getItem(key);
            if (value !== null) {
                preservedValues[key] = value;
            }
        });

        localStorage.clear();

        Object.keys(preservedValues).forEach((key) => {
            localStorage.setItem(key, preservedValues[key]);
        });
    },

    getCurrentUser() {
        return JSON.parse(localStorage.getItem("user-info"));
    },

    changeType(attributes, formattedObject) {
        for (let key in attributes) {
            let item = attributes[key];
            let formattedValue = formattedObject[key]?.value ?? formattedObject[key];

            // Skip if the item is hidden
            if (item?.hide) continue;

            attributes[key] =
                item?.type?.toLowerCase() === "number"
                    ? formattedValue != null
                        ? Number(formattedValue)
                        : null
                    : item?.type?.toLowerCase() === "text"
                      ? String(formattedValue ?? "")
                      : item?.type?.toLowerCase() === "json"
                        ? (() => {
                              try {
                                  return JSON.parse(formattedValue);
                              } catch (e) {
                                  console.error("Invalid JSON format:", formattedValue);
                                  return null;
                              }
                          })()
                        : formattedValue;
        }
        return attributes;
    },
};

export const logger = {
    ...console,
    isDebug: false,
    debugMode(...args) {
        if (!this.isDebug) return;
        // eslint-disable-next-line no-console
        console.log(...args);
    },
};

/**
 * @param {URLSearchParams} q
 * @description Returns queryString starting with ?qparam1=q1&qparam2=q2...etc
 */
export function generateQueryParams(q) {
    let query = "?";

    q.forEach((value, key) => {
        query += `${key}=${value}&`;
    });

    return query.slice(0, -1);
}

export const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

/**
 * @template T - The type of the resolved value of the promises.
 * @param {T[]} array - An array of promises to process.
 */
export async function splitIntoBatches(array, batchSize = 50) {
    const batches = [];
    for (let i = 0; i < array.length; i += batchSize) {
        batches.push(array.slice(i, i + batchSize));
    }
    return batches;
}
