<template>
    <div>
        <nav class="navbar navbar-top navbar-expand navbar-dashboard navbar-dark" id="navbarSupportedContent">
            <section class="left-nav">
                <aside>
                    <router-link to="/" v-if="customerLogo?.logo" class="logo">
                        <img :src="customerLogo.logo" />
                    </router-link>
                    <router-link to="/" class="vue-logo" v-else>
                        <img src="@/assets/images/vuelogix-logo.svg" alt="" />
                    </router-link>
                </aside>
            </section>

            <!-- Navbar links -->
            <section v-if="user">
                <div class="navbar-right">
                    <div class="help-notfcn">
                    <p class="helpline" v-if="configStore.client.config?.helpline">
                        Helpline:
                        <a :href="`tel:${configStore.client.config?.helpline}`">{{
                            configStore.client.config?.helpline
                        }}</a>
                    </p>
                    
                        <notificationInbox />
                    </div>

                    <div>
                        <q-btn>
                            <img
                                class="avatar rounded-circle m-2"
                                alt="Image placeholder"
                                v-if="profile"
                                :src="profile" />
                            <img
                                class="avatar rounded-circle m-2"
                                alt="Image placeholder"
                                v-if="!profile"
                                src="../../../src/assets/images/profile-icon1.svg" />
                            <div class="prof-details">
                                <div class="username">{{ username }}</div>
                                <div class="role">
                                    {{ userRole }} <img src="../../assets/images/arrow-down.svg" alt="" />
                                </div>
                            </div>

                            <q-menu>
                                <q-list style="min-width: 180px; text-align: right">
                                    <q-item clickable v-close-popup>
                                        <q-item-section>
                                            <a href="/account/change-password/" class="dropdown-item">
                                                Change Password
                                                <span class="material-icons">lock</span>
                                            </a>
                                        </q-item-section>
                                    </q-item>
                                    <q-item clickable v-close-popup>
                                        <q-item-section>
                                            <router-link class="dropdown-item" to="/myprofile">
                                                My Profile
                                                <span class="material-icons">person</span>
                                            </router-link>
                                        </q-item-section>
                                    </q-item>
                                    <q-item v-if="isCustomerAdmin" clickable v-close-popup>
                                        <q-item-section>
                                            <router-link class="dropdown-item" to="/settings">
                                                Settings
                                                <span class="material-icons">settings</span>
                                            </router-link>
                                        </q-item-section>
                                    </q-item>
                                    <q-item clickable v-close-popup>
                                        <q-item-section>
                                            <span class="dropdown-item" @click="aboutModal = true">
                                                About
                                                <span class="material-icons">info</span>
                                            </span>
                                        </q-item-section>
                                    </q-item>
                                    <q-item clickable v-close-popup>
                                        <q-item-section>
                                            <button class="logout" @click="logout">
                                                <font-awesome-icon
                                                    icon="fas fa-sign-out-alt"
                                                    title="Logout" />
                                                Logout
                                            </button>
                                        </q-item-section>
                                    </q-item>
                                </q-list>
                            </q-menu>
                        </q-btn>
                    </div>
                </div>
            </section>
        </nav>
        <q-dialog v-model="aboutModal" position="top">
            <q-card style="width: 700px; max-width: 80vw">
                <header class="header-title">
                    <h1 class="main-title">About</h1>
                    <button class="close-icon-secondary-sm" v-close-popup></button>
                </header>

                <q-card-section class="about-wrap">
                    <img src="../../assets/images/about-us.svg" alt="about" />
                    <article v-if="appVersion">
                        <div v-if="appVersion.gen4" class="about-row">
                            <p>Version</p>
                            <h6>: {{ appVersion.gen4 }}</h6>
                        </div>
                        <div v-if="appVersion.IOTFW" class="about-row">
                            <p>IOT Framework</p>
                            <h6>: {{ appVersion.IOTFW }}</h6>
                        </div>
                        <div v-if="appVersion.iotGateway" class="about-row">
                            <p>IOT Gateway Framework</p>
                            <h6>: {{ appVersion.iotGateway }}</h6>
                        </div>
                        <div v-if="appVersion.AIFW" class="about-row">
                            <p>AI Framework</p>
                            <h6>: {{ appVersion.AIFW }}</h6>
                        </div>
                        <div v-if="appVersion.analyticsFW" class="about-row">
                            <p>Analytics Framework</p>
                            <h6>: {{ appVersion.analyticsFW }}</h6>
                        </div>
                    </article>
                </q-card-section>
            </q-card>
        </q-dialog>
    </div>
</template>
<script>
import { useConfigStore } from "@/store/configStore/configStore";
import httpClient from "../../shared/services/http-client";
import userService, { USER_ROLES } from "../../shared/services/user-service";
import notificationInbox from "./notificationInbox/notificationInbox.vue";
export default {
    name: "NavigationBar",
    components: { notificationInbox },
    setup() {
        const configStore = useConfigStore();

        return { configStore };
    },
    props: {
        fetchUser: Boolean,
    },
    data: function () {
        return {
            username: "",
            userRole: "",
            profile: "",
            queryParams: {
                status: "OPEN",
            },
            inprogress: false,
            appVersion: null,
            aboutModal: false,
            customerLogo: "",
            user: null,
        };
    },
    async mounted() {
        if (!this.configStore.isCustomerSet) {
            await this.configStore.setCustomer();
        }
        await this.getCurrentUser();
        this.customerLogo = await userService.getCustomerLogo();
        this.getVersion();
    },
    methods: {
        async getCurrentUser() {
            const user = this.configStore.userData;
            if (user != undefined) {
                this.user = user;
                this.profile = user;
                this.userRole = this.profile.userRole ? this.profile.userRole[0] : "";
                this.username =
                    (this.profile.firstname ? this.profile.firstname : "") +
                    " " +
                    (this.profile.lastname ? this.profile.lastname : "");
                this.profile = this.profile.profile;
            }
        },
        getVersion() {
            if (this.appVersion) return;
            httpClient.get(`/app-version/`, false).then((response) => {
                if (response.data) {
                    this.appVersion = response.data;
                }
            });
        },
        getCurrentCustomer() {
            httpClient.get(`/iotapp/current-customer/`, false).then((response) => {
                if (response.data) {
                    this.customerData = response.data;
                    localStorage.setItem("customer-info", JSON.stringify(response.data));
                }
            });
        },
        async logout() {
            userService.logout();
        },
        navigateToTicket() {
            window.location.href = "/tickets/all?status=OPEN";
        },

        openNav() {
            document.getElementById("mySidenav").style.width = "250px";
        },

        closeNav() {
            document.getElementById("mySidenav").style.width = "0";
        },
    },
    computed: {
        isCustomerAdmin() {
            return this.userRole == USER_ROLES.customeradmin;
        },
    },
};
</script>
<style scoped lang="scss">
.navbar {
    height: 70px;
    // background-color: $light-bg;
    background-color: $white;
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 30px;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px;
    border-bottom: solid 1px $border-primary;

    @media (max-width: 1440px) {
        padding: 0px 15px;
    }
    @media (max-height: 768px) {
        height: 52px;
    }
    @media (max-width: 575px) {
        height: auto;
    }
    .navbar-right {
        display: flex;
        gap: 20px;
        align-items: center;
        @media (max-width: 575px) {
            gap: 5px;
            flex-direction: column-reverse;
            padding-top: 10px;
        }
        p.helpline {
            padding: 6px 10px;
            border: 3px solid $primary;
            border-radius: 5px;
            font-size: 12px;
            color: $secondary;
            font-weight: 500;
            @media (max-width: 575px) {
                font-size: 11px;
                padding: 0;
                border: none;
                border-radius: 0;
            }
        }
    }
}
.avatar {
    height: 35px;
    width: 35px;
}
.role {
    color: $text-secondary-color;
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;

    @media (max-height: 768px) {
        font-size: 10px;
    }
    @media (max-width: 480px) {
        font-size: 10px;
    }
}
.alert-bell {
    font-size: 25px;
}
.alert {
    height: 30px;
    float: right;
    padding: 0.7rem 0.7rem;
}
.alert:hover {
    cursor: pointer;
    opacity: 0.8;
}
.about-wrap {
    display: grid;
    grid-template-columns: 270px auto;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    padding: 15px 30px;

    img {
        width: 100%;
        @media (max-width: 767px) {
            width: 30%;
        }
        @media (max-width: 480px) {
            display: none;
        }
    }
    @media (max-width: 767px) {
        grid-template-columns: 1fr;
    }
}

.prof-details {
    text-align: left;
    margin-left: 10px;
    text-transform: capitalize;
    .username {
        font-size: 16px;
        font-weight: 600;
        color: $primary;
        line-height: normal;

        @media (max-height: 768px) {
            font-size: 13px;
        }

        @media (max-width: 480px) {
            font-size: 13px;
        }
    }
}
.logo {
    height: 45px;
    img {
        height: 45px;
        @media (max-width: 1280px) {
            height: 40px;
        }
        @media (max-width: 575px) {
            width: 100%;
            height: 40px;
        }
    }
    @media (max-width: 575px) {
        width: 100px;
        height: auto;
    }
}
.vue-logo {
    height: 30px;
    img {
        height: 30px;
        @media (max-width: 575px) {
            width: 100%;
        }
    }
    @media (max-width: 575px) {
        width: 100px;
        height: auto;
    }
}

.logout {
    border-radius: 5px;
    background-color: $light-red;
    color: $negative;
    font-size: 14px;
    font-weight: 600;
    padding: 5px;
}
.dropdown-item {
    color: $text-primary-color;
    font-weight: 600;
    span {
        font-size: 16px;
    }
}
.about-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    font-size: 14px;
    color: $text-primary-color;
    border: solid 1px $border-primary;
    margin-bottom: 5px;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: $light-bg;

    h6 {
        font-weight: 600;
        font-size: 14px;
        color: $primary;
    }
}

.header-title {
    padding: 15px 30px;
}

// //////////////////  Sidebar Menu \\\\\\\\\\\\\\\\\\\\\ \\

.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    background-color: $dark;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;

    @media (max-width: 480px) {
        padding-top: 35px;
    }
}

.sidenav a {
    padding: 8px 8px 8px 32px;
    line-height: 30px;
    text-decoration: none;
    font-size: 14px;
    color: $white;
    display: block;
    transition: 0.3s;
    border-bottom: solid 1px #0f7191;
    width: 100%;

    @media (max-width: 480px) {
        font-size: 12px;
    }
}

.sidenav a:hover {
    color: #f1f1f1;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 15px;
    font-size: 24px;
    color: $white;
    cursor: pointer;
}

.sidemenu-icon {
    cursor: pointer;
}
.left-nav {
    display: flex;
    align-items: center;
    gap: 20px;
    padding-top: 5px;
    @media (max-width: 480px) {
        gap: 15px;
    }
}
.help-notfcn{
    display: flex;
    align-items: center;
    gap: 15px;
}
</style>
