import { createRouter, createWebHistory } from "vue-router";
import userService from "@/shared/services/user-service";
import { useUserPermissionStore } from "@/store/configStore/premissionStore";
import { useNameStore } from "@/store/configStore/useNameStore";

//Customer page
const CustomerList = () => import("@/components/customer/CustomerList.vue");
const CustomerForm = () => import("@/components/customer/CustomerForm.vue");
const CustomerView = () => import("@/components/customer/CustomerView.vue");
const CustomerIotCRUD = () => import("@/components/customer/iot/IotForm.vue");

// --------------------- Auth Management -------------------------
const LoginPage = () => import("@/components/LoginPage.vue");
const RegistrationForm = () => import("@/components/registration/RegistrationForm.vue");
const userAdministration = () => import("@/components/users/userAdministration.vue");
const userRegistrationRequestView = () => import("../components/registration/RegistrationView.vue");
const userDetails = () => import("@/components/users/userDetails.vue");
const emailConfirm = () => import("@/components/status/emailConfirm.vue");
const RequestDone = () => import("@/components/status/RequestDone.vue");
const user_Profile = () => import("@/components/My-profile.vue");
const userActivityLogs = () => import("@/components/users/userActivity/baseUserActivityLogs.vue");

// ----------------- Asset pages ------------------------
const AssetDetails = () => import("@/components/assets/AssetDetails.vue");
const AssetFormPage = () => import("@/components/assets/AssetFormPage.vue");
const ImportPage = () => import("@/components/import/ImportPage.vue");
const BulkImportHome = () => import("@/components/import/bulkImportHome.vue");
// ----------------- Device  pages ------------------------
const DeviceList = () => import("@/components/device/DeviceList.vue");
const DeviceDetails = () => import("@/components/device/DeviceDetails.vue");
const DeviceForm = () => import("@/components/device/DeviceForm.vue");
const DiscoverDevice = () => import("@/components/device/DeviceDiscover.vue");
const DiscoverDeviceUpdate = () => import("@/components/device/DiscoverDeviceUpdate.vue");
const AssignDevice = () => import("@/components/device/assign/AssignDevice.vue");
const DeviceInventoryList = () => import("@/components/inventory/DeviceInventoryList.vue");
// ai
const configHome = () => import("@/components/aiConfig/configHome.vue");
const imageAnalysis = () => import("@/components/image-analysis/imageAnalysisHome.vue");
const resultAnalysis = () => import("@/components/image-analysis/resultAnalysis.vue");
const resultVerification = () =>
    import("@/components/image-analysis/result-verification/resultVerification.vue");
// ----------------- Ticket Pages ------------------------
// escalation tickets
const addEscalationTicket = () => import("@/components/ticket/escalationTicket/view/addTicket.vue");
// Master View - Admin
const addTicket = () => import("@/components/ticket/addTicket.vue");
const TicketType = () => import("@/components/ticket/TicketType.vue");

// ----------------- camview Pages ------------------------
const CamViewPage = () => import("@/components/camview/CamViewPage.vue");
const LiveStream = () => import("@/components/camview/liveview/streamLive.vue");
const RecordView = () => import("@/components/camview/recordview/cameraRecordings.vue");
const LivePopupsPage = () => import("@/components/camview/livepopups/LivePopups.vue");

// ----------------- Dashboard Management ------------------------
const dashboardList = () => import("@/components/dashboard-configurator/DashboardList.vue");
const dashboardEdit = () => import("@/components/dashboard-configurator/DashboardEdit.vue");
const dashboardView = () => import("@/components/dashboard-configurator/DashboardView.vue");
const DashboardComponentEditVue = () =>
    import("@/components/dashboard-configurator/DashboardComponentEdit.vue");
const DashboardComponentView = () => import("@/components/dashboard-configurator/DashboardComponentView.vue");

// ----------------- Storage Configuration ------------------------
const StorageConfigurationList = () => import("@/components/storage/StorageConfigurationList.vue");
const StorageConfigurationForm = () => import("@/components/storage/StorageConfigurationForm.vue");

// ----------------- permission Pages ------------------------
const permissionHome = () => import("@/components/permission/permissionHome.vue");
const AssetPermissionPage = () => import("@/components/permission/AssetPermissionPage.vue");
const ticketPermissionPage = () => import("@/components/permission/ticketPermissionPage.vue");

// ----------------- asset management ------------------------
const IFMAppDashboard = () => import("@/components/IFMApp/dashboard/IFMAppDashboard.vue");
const equipmentPage = () => import("@/components/IFMApp/equipments/equipment.vue");
const vendorPage = () => import("@/components/IFMApp/vendor/vendorPage.vue");
const vendorForm = () => import("@/components/IFMApp/vendor/vendorForm.vue");
const issueTypesPage = () => import("@/components/IFMApp/issueTypes/issueTypesPage.vue");
const issueTypeForm = () => import("@/components/IFMApp/issueTypes/issueTypeForm.vue");
const fieldExecutives = () => import("@/components/IFMApp/fieldExecutives/fieldExecutives.vue");
const createFEform = () => import("@/components/IFMApp/fieldExecutives/createFEform.vue");
const storeManager = () => import("@/components/IFMApp/storeManager/storeManager.vue");
const createStoreManager = () => import("@/components/IFMApp/storeManager/createStoreManager.vue");
const infraAdmin = () => import("@/components/IFMApp/infraAdmin/infraAdmin.vue");
const createInfraAdmin = () => import("@/components/IFMApp/infraAdmin/createInfraAdmin.vue");
const clusterView = () => import("@/components/IFMApp/clusterView/clusterView.vue");
const createClusterUser = () => import("@/components/IFMApp/clusterView/createClusterUser.vue");

// ----------------- Genset Dashboard ------------------------
const multiGensetDashboard = () => import("@/components/device-dashboard/genset/multiGensetDashboard.vue");
const gensetDashboard = () => import("@/components/device-dashboard/genset/gensetDashboard.vue");

// ----------------- Rectifier Dashboard ------------------------
const multiRectifierDashboard = () =>
    import("@/components/device-dashboard/rectifier/multiRectifierDashboard.vue");
const rectifierDashboard = () => import("@/components/device-dashboard/rectifier/rectifierDashboard.vue");

// ----------------- Alarm panel Dashboard ------------------------
const alarmPanelDashboard = () => import("@/components/device-dashboard/alarm-panel/alarmPanelDashboard.vue");
const multiAlarmPanelDashboard = () =>
    import("@/components/device-dashboard/alarm-panel/multiAlarmPanelDashboard.vue");

// ----------------- Site Monitoring Dashboard ------------------------
const siteMonitoringDashboard = () =>
    import("@/components/device-dashboard/site-monitoring/siteMonitoringDashboard.vue");

// ----------------- UPS Dashboard ------------------------
const upsDashboard = () => import("@/components/device-dashboard/ups/upsDashboard.vue");

const multiUpsDashboard = () => import("@/components/device-dashboard/ups/multiUpsDashboard.vue");
// ----------------- CCTV Dashboard ------------------------
const cctvDashboard = () => import("@/components/device-dashboard/cctv/cctvDashboard.vue");
const cctvMultisiteDashboard = () => import("@/components/device-dashboard/cctv/cctvMultisiteDashboard.vue");

// ----------------- Meter Device Dashboard ------------------------
const meterDeviceDashboard = () => import("@/components/device-dashboard/meter-device/meterDevice.vue");

// ----------------- Router Dashboard ------------------------
const routerDashboard = () => import("@/components/device-dashboard/router-dashboard/routerDashboard.vue");

// ----------------- Rtu Eth  Dashboard ------------------------
const ethMultisiteDashboard = () => import("@/components/device-dashboard/rtu-eth/rtuMultiSiteDashboard.vue");
const ethSingleSiteDashboard = () =>
    import("@/components/device-dashboard/rtu-eth/rtuSingleSiteDashboard.vue");

// ----------------- Home page ------------------------
const HomePage = () => import("@/components/HomePage.vue");

// ----------------- asset page ------------------------
const AssetsPage = () => import("@/components/assets/AssetPage.vue");

// ----------------- Device Health Dashboard ------------------------
const DeviceHealthDashboard = () => import("@/components/device/DeviceHealthDashboard.vue");

// -------------------Device Template Dashboard ----------------
const DeviceTemplate = () => import("@/components/device-template/DeviceTemplate.vue");
const ViewDeviceTemplate = () => import("@/components/device-template/ViewDeviceTemplate.vue");
const editDeviceTemplate = () => import("@/components/device-template/editDeviceTemplate.vue");

// ----------------- Ticket Dashboard ------------------------
// escalation tickets
const escalationTickets = () => import("@/components/ticket/escalationTicket/view/escalationTickets.vue");
const viewEscalationTicket = () => import("@/components/ticket/escalationTicket/view/viewTicket.vue");
// Master View - Admin
const TicketPage = () => import("@/components/ticket/TicketPage.vue");
const viewTickets = () => import("@/components/ticket/viewTickets.vue");

// ----------------------- Escalations ---------------------
const escalations = () => import("@/components/escalations/escalationHome.vue");
const createEscalationPolicy = () => import("@/components/escalations/createEscalationPolicy.vue");
const viewEscalationPolicy = () => import("@/components/escalations/viewEscalationPolicy.vue");

// ----------------- Work orders Dashboard ------------------------
const workOrders = () => import("@/components/workOrders/workOrders.vue");
const viewWorkOrder = () => import("@/components/workOrders/viewWorkOrder.vue");
// calendar
const calendarWrap = () => import("@/components/calendar/calendarWrap.vue");
const createCalendar = () => import("@/components/calendar/createEventForm.vue");

// ---------------- attendance management
const attendanceManagement = () => import("@/components/IFMApp/attendanceManagement/attendanceRegister.vue");

// NPS App
const formsPage = () => import("@/components/NPSApp/formsPage.vue");
const formsViewPage = () => import("@/components/NPSApp/viewForm.vue");
const vueFormBuilder = () => import("@/components/NPSApp/vueFormGenerator.vue");

// Access control Dashboard

const accessControlDashboard = () =>
    import("@/components/device-dashboard/access-control-dashboard/accessControlDashboard.vue");

// ----------------- Others ------------------------
const supersetAnalytics = () => import("@/components/analytics/Superset.vue");
const appAdmin = () => import("@/components/extensions/djangoAdmin.vue");
const extension = () => import("@/components/extensions/extension.vue");
const localadmin_Dashboard = () => import("@/components/dashboards/localAdmin-Dashboard.vue");
const Page404 = () => import("@/components/status/page-404.vue");
const accessDenied = () => import("@/shared/pages/accessDenied.vue");
const cookiePolicy = () => import("../components/cookieConsent/cookiePolicy.vue");
const ServerDiagnostics = () => import("@/components/diagnostics/serverDiagnostics.vue");
// ----------------- Views ------------------------ Don't import dynamically
import userView from "@/views/userView.vue";
import commonView from "@/views/commonView.vue";
import gensetView from "@/views/gensetView.vue";
import { useConfigStore } from "@/store/configStore/configStore";
// import siteMonitoringDashboard from "@/components/device-dashboard/site-monitoring/siteMonitoringDashboard.vue";

const beforeEnter = async function (to, from, next) {
    if (localStorage.getItem("user-info")) {
        const userPermissionStore = useUserPermissionStore();
        // TODO: Need to remove nameStore after complete integrations of config store
        const nameStore = useNameStore();

        const configs = useConfigStore();
        if (!userPermissionStore.isPermissionSet()) {
            userPermissionStore.setPermissions();
        }
        if (!nameStore.isCustomerSet) {
            await nameStore.setCustomer();
        }
        if (!configs.isCustomerSet) {
            await configs.setCustomer();
        }
        next();
    } else {
        let user = await userService.getCurrentUser();
        if (user) {
            window.location.reload();
        } else {
            const nextUrl = encodeURIComponent(window.location.pathname + window.location.search);
            const loginUrlBase = localStorage.getItem("x-log-role") || "auth";
            const loginUrl = `/${loginUrlBase}/login/?next=${nextUrl}`;
            window.location.href = loginUrl;
        }
    }
};

const routes = [
    {
        path: "/",
        name: "userView",
        component: userView,
        children: [
            {
                path: "",
                name: "home",
                component: HomePage,
            },
            {
                path: "/folder/:folderId",
                name: "folder-view",
                component: HomePage,
            },
            {
                path: "/analytics",
                name: "analaytics-home",
                component: supersetAnalytics,
                beforeEnter,
            },
            // assets
            {
                path: "assets",
                name: "assets",
                component: AssetsPage,
                beforeEnter,
            },
            {
                path: "assets/view/:id",
                name: "asset-details",
                component: AssetDetails,
                beforeEnter,
            },
            {
                path: "assets/create",
                name: "asset-create",
                component: AssetFormPage,
                beforeEnter,
            },
            {
                path: "assets/edit/:id",
                name: "asset-edit",
                component: AssetFormPage,
                beforeEnter,
            },
            {
                path: "assign-asset",
                name: "AssetPermission",
                component: AssetPermissionPage,
                beforeEnter,
            },

            //customer
            {
                path: "/customers",
                name: "CustomerList",
                component: CustomerList,
                beforeEnter,
            },
            {
                path: "/customers/create",
                name: "CustomerForm",
                component: CustomerForm,
                beforeEnter,
            },
            {
                path: "/customers/:id/view/",
                name: "CustomerView",
                component: CustomerView,
                beforeEnter,
            },
            {
                path: "/customers/:id/edit/",
                name: "CustomerEdit",
                component: CustomerForm,
                beforeEnter,
            },
            {
                path: "/customers/:id/iot-fw-cred/",
                name: "CustomerIotCRUD",
                component: CustomerIotCRUD,
                beforeEnter,
            },
            // devices
            {
                path: "/devices",
                name: "DeviceList",
                component: DeviceList,
                beforeEnter,
            },
            {
                path: "/devices/:id/view",
                name: "DeviceDetails",
                component: DeviceDetails,
                beforeEnter,
            },
            {
                path: "/devices/:id/edit",
                name: "EditDevice",
                component: DeviceForm,
                beforeEnter,
            },
            {
                path: "/devices/discover",
                name: "DiscoverDevice",
                component: DiscoverDevice,
                beforeEnter,
            },
            {
                path: "/devices/discover/:id/edit",
                name: "DiscoverDeviceUpdate",
                component: DiscoverDeviceUpdate,
                beforeEnter,
            },
            {
                path: "/device/create",
                name: "CreateDevice",
                component: DeviceForm,
                beforeEnter,
            },
            {
                path: "/devices/assign",
                name: "AssignDevice",
                component: AssignDevice,
                beforeEnter,
            },
            {
                path: "/device-health",
                name: "DeviceHealth",
                component: DeviceHealthDashboard,
                beforeEnter,
            },

            // device Template
            {
                path: "/device-template",
                name: "Devicetemplate",
                component: DeviceTemplate,
                beforeEnter,
            },
            {
                path: "/view-device-template/view/:templateId",
                name: "view-device-template",
                component: ViewDeviceTemplate,
                beforeEnter,
            },

            {
                path: "/view-device-template/edit/:templateId",
                name: "edit-device-template",
                component: editDeviceTemplate,
                beforeEnter,
            },

            {
                path: "/device-template/create/",
                name: "add-device-template",
                component: editDeviceTemplate,
                beforeEnter,
            },
            // device inventory
            {
                path: "/device-inventory",
                name: "DeviceInventoryList",
                component: DeviceInventoryList,
                beforeEnter,
            },
            // ticket
            {
                path: "/tickets",
                name: "TicketManagement",
                component: TicketType,
                beforeEnter,
            },
            {
                path: "/tickets/:type",
                name: "all-tickets",
                component: TicketPage,
                beforeEnter,
            },
            {
                path: "/tickets/:type/create",
                name: "add-ticket",
                component: addTicket,
                beforeEnter,
            },
            {
                path: "/tickets/:type/:id",
                name: "edit-tickets",
                component: addTicket,
                beforeEnter,
            },
            {
                path: "/tickets/:type/view",
                name: "view-ticket",
                component: viewTickets,
                beforeEnter,
            },
            // escalation ticket view
            {
                path: "/escalation-tickets",
                name: "escalation-tickets",
                component: escalationTickets,
                beforeEnter,
            },
            {
                path: "/escalation-tickets/create",
                name: "add-escalation-ticket",
                component: addEscalationTicket,
                beforeEnter,
            },
            {
                path: "/escalation-tickets/:id/edit",
                name: "edit-escalation-ticket",
                component: addEscalationTicket,
                beforeEnter,
            },
            {
                path: "/escalation-tickets/view",
                name: "view-escalation-ticket",
                component: viewEscalationTicket,
                beforeEnter,
            },
            // work orders
            {
                path: "/work-orders/",
                name: "work-orders",
                component: workOrders,
                beforeEnter,
            },
            {
                path: "/work-orders/:id/view",
                name: "view-work-order",
                component: viewWorkOrder,
                beforeEnter,
            },
            // permissions
            {
                path: "/permissions/",
                name: "permissions",
                component: permissionHome,
                beforeEnter,
            },
            {
                path: "/assign-ticket",
                name: "assign-ticket",
                component: ticketPermissionPage,
                beforeEnter,
            },

            // Others
            {
                path: "/import",
                name: "import",
                component: ImportPage,
                beforeEnter,
            },
            {
                path: "/bulk-import",
                name: "bulk-import",
                component: BulkImportHome,
                beforeEnter,
            },
            {
                path: "/dashboards",
                name: "dashboard-list",
                component: dashboardList,
                beforeEnter,
            },
            {
                path: "/dashboards/create",
                name: "dashboard-create",
                component: dashboardEdit,
                beforeEnter,
            },
            {
                path: "/dashboards/:id/edit",
                name: "dashboard-editor",
                component: dashboardEdit,
                beforeEnter,
            },
            // components
            {
                path: "/dashboards/component/create",
                name: "dashboard-component-create",
                component: DashboardComponentEditVue,
                beforeEnter,
            },
            {
                path: "/dashboards/component/:id/edit",
                name: "dashboard-component-editor",
                component: DashboardComponentEditVue,
                beforeEnter,
            },
            {
                path: "/dashboards/component/:id/view",
                name: "dashboard-component-viewer",
                component: DashboardComponentView,
                beforeEnter,
            },
            {
                path: "/dashboards/:id/view",
                name: "dashboard-view",
                component: dashboardView,
                beforeEnter,
            },
            {
                path: "/myprofile",
                name: "user_Profile",
                component: user_Profile,
                beforeEnter,
            },
            {
                path: "/administration",
                name: "customer-administration",
                component: localadmin_Dashboard,
                beforeEnter,
            },
            {
                path: "/users",
                name: "request-registration",
                component: userAdministration,
                beforeEnter,
            },
            {
                path: "/user-activity-logs",
                name: "user-activity-logs",
                component: userActivityLogs,
                beforeEnter,
            },
            {
                path: "/registration-requests/:id/view",
                name: "registrationRequestView",
                component: userRegistrationRequestView,
                beforeEnter,
            },
            {
                path: "/user/:id",
                name: "user-details",
                component: RegistrationForm,
                beforeEnter,
            },
            {
                path: "/user-details/:id",
                name: "userDetails",
                component: userDetails,
                props: true,
                beforeEnter,
            },
            {
                path: "/email-done",
                name: "email-done",
                component: emailConfirm,
                beforeEnter,
            },
            {
                path: "/app-admin",
                name: "django-extension-app",
                component: appAdmin,
                props: true,
                beforeEnter,
            },
            {
                path: "/extension",
                name: "extension-app",
                component: extension,
                meta: { hideNavigation: true },
                props: true,
                beforeEnter,
            },
            //Storage Configuration
            {
                path: "/storages",
                name: "Storage Configuration",
                component: StorageConfigurationList,
                props: true,
                beforeEnter,
            },
            {
                path: "/storages/create",
                name: "Storage Configuration Form",
                component: StorageConfigurationForm,
                props: true,
                beforeEnter,
            },
            {
                path: "/storages/:id/edit",
                name: "Storage Configuration Edit",
                component: StorageConfigurationForm,
                props: true,
                beforeEnter,
            },

            // image analysis
            {
                path: "/result-analysis",
                name: "result-analysis",
                component: resultAnalysis,
                props: true,
                beforeEnter,
            },
            {
                path: "/result-verification",
                name: "result-verification",
                component: resultVerification,
                beforeEnter,
            },
            {
                path: "/image-analysis",
                name: "image-analysis",
                component: imageAnalysis,
                props: true,
                beforeEnter,
            },

            // camview
            {
                path: "/camview",
                name: "camview",
                component: CamViewPage,
                props: true,
                beforeEnter,
            },
            {
                path: "/camview/live",
                name: "live-view",
                component: LiveStream,
                beforeEnter,
            },
            {
                path: "/camview/record",
                name: "record-view",
                component: RecordView,
                beforeEnter,
            },
            {
                path: "/camview/popups",
                name: "live-popups",
                component: LivePopupsPage,
                beforeEnter,
            },

            // Ai config
            {
                path: "/cameras/:id/configs",
                name: "camera-config",
                component: configHome,
                beforeEnter,
            },

            // asset-management
            {
                path: "/asset-management",
                name: "asset-management",
                component: IFMAppDashboard,
                beforeEnter,
            },
            {
                path: "/equipments",
                name: "equipments",
                component: equipmentPage,
                beforeEnter,
            },
            {
                path: "/vendors",
                name: "vendorPage",
                component: vendorPage,
                beforeEnter,
            },
            {
                path: "/vendors/create",
                name: "CreateVendor",
                component: vendorForm,
                beforeEnter,
            },
            {
                path: "/vendors/edit/:id",
                name: "editVendor",
                component: vendorForm,
                beforeEnter,
            },
            {
                path: "/field-executives",
                name: "fieldExecutives",
                component: fieldExecutives,
                beforeEnter,
            },
            {
                path: "/field-executives/create",
                name: "CreateFieldExecutives",
                component: createFEform,
                beforeEnter,
            },
            {
                path: "/field-executives/edit/:id",
                name: "editFieldExecutives",
                component: createFEform,
                beforeEnter,
            },
            // store manager
            {
                path: "/store-manager",
                name: "storeManager",
                component: storeManager,
                beforeEnter,
            },
            {
                path: "/store-manager/create",
                name: "CreateStoreManager",
                component: createStoreManager,
                beforeEnter,
            },
            {
                path: "/store-manager/edit/:id",
                name: "editStoreManagers",
                component: createStoreManager,
                beforeEnter,
            },
            // infra admin
            {
                path: "/infra-admin",
                name: "infraAdmins",
                component: infraAdmin,
                beforeEnter,
            },
            {
                path: "/infra-admin/create",
                name: "createInfraAdmin",
                component: createInfraAdmin,
                beforeEnter,
            },
            {
                path: "/infra-admin/edit/:id",
                name: "editInfraAdmin",
                component: createInfraAdmin,
                beforeEnter,
            },
            {
                path: "/issue-types",
                name: "issueTypes",
                component: issueTypesPage,
                beforeEnter,
            },
            {
                path: "/issue-types/create/",
                name: "createIssueType",
                component: issueTypeForm,
                beforeEnter,
            },
            {
                path: "/issue-types/edit/:id",
                name: "editIssueType",
                component: issueTypeForm,
                beforeEnter,
            },
            // cluster view
            {
                path: "cluster-view",
                name: "clusterView",
                component: clusterView,
                beforeEnter,
            },
            {
                path: "cluster-view/create",
                name: "createClusterUser",
                component: createClusterUser,
                beforeEnter,
            },
            {
                path: "cluster-view/:id/edit",
                name: "editClusterUser",
                component: createClusterUser,
                beforeEnter,
            },
            // settings
            {
                path: "/settings",
                name: " app-settings",
                component: () => import("@/components/settings/AppSettings.vue"),
            },
            {
                path: "escalations",
                name: "escalation-policy",
                component: escalations,
                beforeEnter,
            },
            {
                path: "escalations/create",
                name: "create-escalation-policy",
                component: createEscalationPolicy,
                beforeEnter,
            },
            {
                path: "escalations/:id/edit",
                name: "edit-escalation-policy",
                component: createEscalationPolicy,
                beforeEnter,
            },
            {
                path: "escalations/:id/view",
                name: "view-escalation-policy",
                component: viewEscalationPolicy,
                beforeEnter,
            },
            // calendar
            {
                path: "calendar",
                name: "calendar",
                component: calendarWrap,
                beforeEnter,
            },
            {
                path: "calendar/create",
                name: "create-calendar",
                component: createCalendar,
                beforeEnter,
            },
            {
                path: "calendar/:eventId/edit",
                name: "edit-calendar",
                component: createCalendar,
                beforeEnter,
            },
            // attendance management
            {
                path: "attendance-management",
                name: "attendance-management",
                component: attendanceManagement,
                beforeEnter,
            },
            {
                path: "forms",
                name: "forms",
                component: formsPage,
                beforeEnter,
            },
            {
                path: "forms/:id/view",
                name: "form-view",
                component: formsViewPage,
                beforeEnter,
            },
            {
                path: "form-builder",
                name: "form-builder",
                component: vueFormBuilder,
                beforeEnter,
            },
            // router dashboard
            {
                path: "/access-control",
                name: "access-control-dashboard",
                component: accessControlDashboard,
                beforeEnter,
            },
            // Server Diagnostics
            {
                path: "/diagnostics",
                name: "server-diagnostics",
                component: ServerDiagnostics,
                beforeEnter,
            },
            // cookie policy
            {
                path: "/cookie-policy",
                name: " cookie-policy",
                component: cookiePolicy,
                meta: { hideNavigation: true },
            },

            // router dashboard

            {
                path: "/router",
                name: "router-dashboard",
                component: routerDashboard,
                beforeEnter,
            },

            // RTU ETH Dashboard

            {
                path: "/rtu-multisite",
                name: "eth-multisite",
                component: ethMultisiteDashboard,
                beforeEnter,
            },

            {
                path: "eth-single-site/view/:id",
                name: "eth-single-site",
                component: ethSingleSiteDashboard,
                beforeEnter,
            },
        ],
    },
    // register
    {
        path: "/register",
        component: commonView,
        children: [
            {
                path: "",
                name: "register",
                component: RegistrationForm,
                beforeEnter,
            },
            {
                path: "request-done",
                name: "request-done",
                component: RequestDone,
                beforeEnter,
            },
        ],
    },

    {
        name: "Genset",
        path: "/genset",
        component: gensetView,
        children: [
            // Genset Dashboard
            {
                path: "dashboard",
                name: "genset-home",
                component: multiGensetDashboard,
                beforeEnter,
            },
            {
                path: "view/:id",
                name: "genset-site",
                component: gensetDashboard,
                beforeEnter,
            },
        ],
    },
    {
        name: "Alarm-panel",
        path: "/alarm-panel",
        component: gensetView,
        children: [
            // Alarm Panel Dashboard

            {
                path: "dashboard",
                name: "alarmpanel-home",
                component: multiAlarmPanelDashboard,
                beforeEnter,
            },
            {
                path: "view/:id",
                name: "alarmpanel-site",
                component: alarmPanelDashboard,
                beforeEnter,
            },
        ],
    },

    {
        name: "Rectifier",
        path: "/rectifier",
        component: gensetView,
        children: [
            // Rectifier Dashboard,
            {
                path: "dashboard",
                name: "rectifier-home",
                component: multiRectifierDashboard,
                beforeEnter,
            },
            {
                path: "view/:id/:deviceId",
                name: "rectifier-site",
                component: rectifierDashboard,
                beforeEnter,
            },
        ],
    },
    {
        name: "UPS",
        path: "/ups",
        component: gensetView,
        children: [
            // UPS Dashboard

            {
                path: "dashboard",
                name: "ups-home",
                component: multiUpsDashboard,
                beforeEnter,
            },
            {
                path: "view/:id/:deviceId",
                name: "ups-site",
                component: upsDashboard,
                beforeEnter,
            },
        ],
    },
    {
        path: "/sitemonitoring",
        name: "site-monitoring-dashboard",
        component: siteMonitoringDashboard,
        beforeEnter,
    },

    {
        name: "Meter",
        path: "/meter-device",
        component: gensetView,
        children: [
            // Meter Device Dashboard

            {
                path: "dashboard",
                name: "meter-home",
                component: meterDeviceDashboard,
                beforeEnter,
            },
        ],
    },
    {
        name: "cctv",
        path: "/cctv",
        component: gensetView,
        children: [
            // CCTV Dashboard
            {
                path: "dashboard",
                name: "cctv-home",
                component: cctvMultisiteDashboard,
                beforeEnter,
            },
            {
                path: "view/:id",
                name: "cctv-site",
                component: cctvDashboard,
                beforeEnter,
            },
        ],
    },

    {
        path: "/auth/login",
        name: "login",
        component: LoginPage,
        beforeEnter: function (to, from, next) {
            if (localStorage.getItem("user-info")) {
                next("/");
            } else next();
        },
    },
    {
        path: "/:role/login",
        name: "role-login",
        component: LoginPage,
        beforeEnter: function (to, from, next) {
            if (localStorage.getItem("user-info")) {
                next("/");
            } else next();
        },
    },
    { path: "/access-denied", name: "403", component: accessDenied },
    { path: "/:pathMatch(.*)*", name: "404", component: Page404 },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
